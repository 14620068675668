import LogRocket from 'logrocket';
import {isStage, mobileCheck} from './src/utils';

import './src/global.css';

mobileCheck();

if(!isStage) {
  LogRocket.init('8lugdu/gil-landing')
}
